import clsx from 'clsx';
import { useFormContext } from 'react-hook-form';

const BaseInput = ({ type, className, name, required, fullWidth, ...props }) => {
  const { register } = useFormContext();

  return (
    <>
      <input
        type={type}
        className={clsx('form-input bg-gray-900 border-0 rounded-md py-4 focus:ring-2 focus:ring-white', fullWidth && 'w-full', className)}
        {...register(name, {
          required: required ? 'This field is required' : false,
        })}
        {...props}
      />
    </>
  );
};

export default BaseInput;
